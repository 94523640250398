import React, { Fragment } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'

import { color } from '../shared/styles'
import getTelephoneNumber from '../../utils/getTelephoneNumber'

const Wrapper = styled.div`
  width: 375px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${color.white40};
  text-align: center;
  color: ${color.white};
  position: absolute;
  bottom: 0;
  left: 0;

  @media screen and (max-width: 768px) {
    width: 100%;
    border-right: none;
  }

  @media screen and (max-height: 485px) {
    width: 100%;
    border-right: none;
  }

  a {
    color: ${color.white};
    text-decoration: none;
  }
`

const Telephones = ({ phones }) => {
  return (
    <Wrapper>
      <dl>
        {phones?.map(({ title, number }, idx) => (
          <Fragment key={`phone-${idx}`}>
            <dt><FontAwesomeIcon icon={title.toLowerCase() === 'whatsapp' ? faWhatsapp : faPhoneAlt} /></dt>
            <dd><a href={title === 'Llamadas' ? `tel:+${getTelephoneNumber(number)}` : `https://wa.me/${getTelephoneNumber(number)}`}>{number}</a></dd>
          </Fragment>
        )
        )}
      </dl>
    </Wrapper>
  )
}

export default Telephones;
