import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import Seo from '../components/seo'
import Slider from '../components/HomeSlider'
import Telephones from '../components/Telephones'
import CallToAction from '../components/CallToAction'
import Logo from '../components/shared/Logo'
import Button from '../components/Button/Button'
import { color, fontSize } from '../components/shared/styles'
import SmallProduct from '../components/SmallProduct'
import useWindowSize from '../hooks/useWindowSize'
import Container from '../components/shared/Container'

const TopSection = styled.section`
  height: 100vh;
  min-height: 740px;
  position: relative;
`

const StripSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 70px 0;
  background: ${color.lighterGrey};
  align-items: center;
  justify-content: center;

  h2 {
    font-size: ${fontSize.md};
    text-transform: uppercase;
    text-align: center;
  }

  p {
    max-width: 600px;
    font-size: ${fontSize.sm};
    text-align: center;
    padding: 30px;
  }
`

const ProductsSection = styled.section`
  height: auto;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${props => props.bgImg || ''});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 106px;

  > div:nth-child(2) {
    text-align: center;
  }

  @media screen and (max-width: 769px) {
    padding: 90px 50px;
  }
`

const ProductsShelf = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  align-items: flex-start;
  margin-bottom: 40px;

  @media screen and (max-width: 1145px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`

const CallToActionSection = styled.section`
  height: auto;
`

const AboutUsSection = styled.section`
  background: url(${props => props.bgImage || ''});
  background-color: ${color.white};
  background-size: 300px;
  background-position: right;
  background-repeat: no-repeat;
  min-height: max-content;

  @media screen and (max-width: 768px) {
    background-size: 200px;
    background: linear-gradient(0deg,rgba(44,130,63,0.7),rgba(255,255,255,1)), url(${props => props.bgImage || ''});
  }

  h2 {
    margin-bottom: 40px;
  }

  > div {
    padding: 106px 150px 106px 106px;
    min-height: max-content;
    width: 75%;
    text-align: center;
    line-height: normal;
    max-width: 1260px;
    margin: 0 auto;

    @media screen and (max-width: 1290px) {
      margin: 0;
    }

    @media screen and (max-width: 768px) {
      padding: 50px;
      width: 100%;
    }
  }
`

const Logos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  > div {
    background-position: center center;

    &:not(:nth-child(2)) {
      background-size: contain;
    }
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`

const MissionVissionSection = styled.section`
  height: max-content;
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  align-items: center;

  > div {
    padding: 120px 103px;
    color: ${color.white};
    background-color: ${color.green};
    text-align: center;
    height: 100%;

    &:nth-of-type(2) {
      padding: 70px 0;
      
      .divider {
        border: 1px solid ${color.white40};
        height: 100%;
      }

      @media screen and (max-width: 768px) {
        padding: 5px 30px;

        .divider {
          border: none;
          border-top: 1px solid ${color.white40};
        }
      }
    }

    h2 {
      font-size: ${fontSize.md};
    }

    p {
      margin-bottom: 0;
    }

  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;

    > div {
      padding: 40px 30px 45px 30px;
    }
  }
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityCompanyValue {
        nodes {
          name
          description
          image {
            asset {
              url
            }
          }
        }
      }
      allSanityTelephones {
        nodes {
          name
          number
        }
      }
      allSanityCta {
        nodes {
          id
          name
          text
          to
          buttonText
          bgImgUrl {
            asset {
              url
            }
          }
        }
      }
      allSanityCompanyGoals {
        edges {
          node {
            mission
            vision
          }
        }
      }
      allSanityAboutus {
        edges {
          node {
            title
            description
            bgImage {
              asset {
                url
              }
            }
            logos {
              asset {
                url
              }
            }
          }
        }
      }
      allSanityProduct {
        nodes {
          id
          name
          performance
          displayHome
          subTitle
          description
          route {
            current
          }
          bgImage {
            asset {
              url
            }
          }
          images {
            asset {
              url
            }
          }
          cutsheet {
            asset {
              url
            }
          }
          footNotes
        }
      }
      allSanitySiteSettings {
        nodes {
          homeSecondSectionBg {
            asset {
              url
            }
          }
          homeStripSection {
            sectionTitle
            sectionDescription
          }
        }
      }
    }
  `)

  const slides = data?.allSanityCompanyValue?.nodes?.map(
    ({ name, description, image }) => ({ title: name, description, backgroundImg: image?.asset?.url })
  )

  const phones = data?.allSanityTelephones?.nodes?.map(
    ({ name, number }) => ({ title: name, number })
  )

  const ctas = data?.allSanityCta?.nodes?.map(
    ({ id, name, text, to, bgImgUrl, buttonText }) => ({ id, name, text, to, bgImgUrl: bgImgUrl.asset.url, buttonText })
  )

  const goals = data?.allSanityCompanyGoals?.edges?.map(
    ({ node : { mission, vision } }) => ({ mission, vision })
  )[0]

  const aboutUs = data?.allSanityAboutus?.edges?.map(
    ({ node : { title, description, bgImage, logos }}) => ({ title, description, bgImage: bgImage.asset.url, logos: logos.map(logo => ({ url: logo.asset.url })) })
  )[0]

  const products = data?.allSanityProduct?.nodes?.map(
    ({
      id,
      name,
      route,
      displayHome,
      performance,
      subTitle,
      description,
      bgImage,
      images,
      cutsheet,
      footNotes
    }) => ({
      id,
      name,
      route: route.current,
      displayHome,
      performance,
      subTitle,
      description,
      bgImage: bgImage.asset.url,
      images: images.map(({ asset }) => ({ url: asset.url })),
      cutsheet: cutsheet.asset.url,
      footNotes
    })
  )

  const productsSectionBg = data?.allSanitySiteSettings?.nodes[0]?.homeSecondSectionBg?.asset?.url;

  const stripSectionContent = data?.allSanitySiteSettings?.nodes[0]?.homeStripSection;

  const home1Cta = ctas.find(({ name }) => name === 'home_1')

  const isMobile = useWindowSize().width < 769;

  return (
  <Layout>
    <Seo
      title="Soluciones para la cosecha de flores de cannabis"
      description="Distribuidores oficiales de productos Twister en Uruguay. Proveemos soluciones para la cosecha de flores de cannabis."
      keywords={['inicio', 'twister', 'cosecha', 'planta', 'productor', 'cultivo']}
    />
    <TopSection>
      <Slider
        slides={slides}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        interval={9000}
        autoPlay={true}
        infiniteLoop={true}
      />
      <Telephones phones={phones} />
    </TopSection>
    <StripSection>
      <h2>{stripSectionContent.sectionTitle}</h2>
      <p>{stripSectionContent.sectionDescription}</p>
      <Logo height={56} isBgCentered />
    </StripSection>
    <ProductsSection bgImg={productsSectionBg}>
      <Container>
        <ProductsShelf>
          {products
            .filter(product => product.displayHome)
            .map(product => <SmallProduct key={product.id} product={product} />)}
        </ProductsShelf>
        <Button to="/productos" height="50px" variant="black" align="center">PRODUCTOS</Button>
      </Container>
    </ProductsSection>
    <CallToActionSection>
      {
        home1Cta && (
          <CallToAction
            text={home1Cta.text}
            to={home1Cta.to}
            buttonText={home1Cta.buttonText}
            bgImgUrl={home1Cta.bgImgUrl}
            isBgFixed
          />
        )
      }
    </CallToActionSection>
    <AboutUsSection bgImage={aboutUs?.bgImage}>
      <div>
        <h2>{aboutUs?.title}</h2>
        {aboutUs?.description?.split('\\n')?.map((paragraph, idx) => <p key={`p-${idx}`}>{paragraph}</p>)}
        <Logos>
          {aboutUs?.logos?.map(
            ({ url }, idx) => 
              <Logo
                key={`logo-${idx}`}
                src={url}
                opacity={isMobile ? 0.7 : 0.4 }
                height={100}
                isWhite={isMobile}
              />
            )}
        </Logos>
      </div>
    </AboutUsSection>
    <MissionVissionSection>
      <div>
        <h2>Misión</h2>
        <p>{goals.mission}</p>
      </div>
      <div>
        <div className="divider"></div>
      </div>
      <div>
        <h2>Visión</h2>
        <p>{goals.vision}</p>
      </div>
    </MissionVissionSection>
  </Layout>
)}

export default IndexPage
