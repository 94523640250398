import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'

import Slide from './Slide'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`

const StyledCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;

  .carousel {
    &.carousel-slider {
      height: 100%;
    }
    .slider-wrapper {
      height: 100%;
    }
    .slider {
      height: 100%;
    }
  }
`

const Slider = ({ slides, infiniteLoop, showThumbs, showArrows, showStatus, interval, autoPlay }) => {
  return (
    <Wrapper>
      <StyledCarousel
        infiniteLoop={infiniteLoop}
        showThumbs={showThumbs}
        showArrows={showArrows}
        showStatus={showStatus}
        interval={interval}
        autoPlay={autoPlay}
      >
        {slides.map((slide, idx) => <Slide key={`slide-${idx}`} title={slide.title} description={slide.description} backgroundImg={slide.backgroundImg} />)}
      </StyledCarousel>
    </Wrapper>
  )
}

export default Slider;


