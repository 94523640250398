import React from 'react'
import styled from 'styled-components'

import { fontSize, color } from '../shared/styles'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${(props) => props.backgroundImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Hero = styled.div`
  width: 500px;
  height: auto;
  color: ${color.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    font-size: ${fontSize.xl};
    font-weight: bolder;
    text-transform: uppercase;
  }

  p {
    font-size: ${fontSize.sm};
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 60px !important;

    h2 {
      font-size: ${fontSize.lg};
    }
  }
  
  @media screen and (max-width: 550px) {
    padding: 0 30px;
    width: 100%;
  }
`

const Slide = ({ title, description, backgroundImg }) => {
  return (
    <Wrapper backgroundImg={backgroundImg}>
      <Hero>
        <h2>{ title }</h2>
        <p>{ description }</p>
      </Hero>
    </Wrapper>
  )
}

export default Slide
