import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import { color, fontSize, letterSpacing } from '../shared/styles';

const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-decoration: none;
  color: ${color.black};

  h3 {
    color: ${color.green};
    text-align: center;
    letter-spacing: ${letterSpacing.large};
    text-transform: uppercase;
    font-size: ${fontSize.sm};
    font-weight: 500;
  }

  img {
    height: 100%;
    width: max-content;
    max-height: 160px;
    object-fit: contain;
    transition: all 0.2s;
  }
  
  p {
    font-size: 1.1rem;
    line-height: 1.45rem;
    font-weight: 800;
    text-align: center;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }
`

const SmallProduct = ({ product: { name, images, subTitle, route } }) => {
  return (
    <Wrapper to={`producto${route}`}>
      <h3>{name}</h3>
      <img src={images[0]?.url} alt={name} />
      <p>{subTitle}</p>
    </Wrapper>
  )
}

export default SmallProduct;
